body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f8f8;
}

.moviePercentage {
  font-family: "Droid Sans";
}

.icons .notification-circle {
  color: #fff !important;
  background-color: red;
  text-shadow: none !important;
  border-radius: 50%;
  height: 12px;
  width: 12px;
  font-size: 0.8rem !important;
  padding: 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.checked {
  color: orange;
}

.activity > .ui.feed > .event > .label + .content {
  margin: 0rem 0 0.357143em 1.142857em;
}

.ui.card.link {
  width: 180px;
  color: #000;
  border-radius: 0;
}
.ui.card.link > .content {
  padding: 0.7rem;
}

.ui.menu .item {
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0 1rem;
}

.ui.tiny.modal {
  border-radius: 0;
  border-bottom: 5px solid #db2828;
}
.ui.tiny.modal > .actions,
.ui.tiny.modal > .ui.header {
  background: #fff;
  border: none;
}

.ui.menu .active.item {
  font-weight: 600;
  border-bottom: 3px solid red;
}

.ui.card > .content .star.icon:hover,
.ui.cards > .card > .content .star.icon:hover {
  opacity: 0.75;
  color: unset;
}

.ui.card > .content,
.ui.cards > .card > .content {
  display: flex;
  align-items: normal;
  flex-direction: column;
  justify-content: space-between;
}

.ui.cards > .card > .extra.content:after,
.ui.huge.borderless.top.menu:after {
  display: none;
}
.ui.dropdown .menu {
  right: 0;
  left: unset;
}

.ui.cards > .card > .extra.content {
  flex-direction: row;
  padding: 0.5rem;
  align-items: center;
}

.ui.rating .active.icon,
.ui.rating .icon.selected {
  color: #ffb600 !important;
}

.ui.cards > .ui.card > .image > .image {
  height: 280px;
}

.ui.compact.buttons {
  flex-flow: wrap;
}

.ui.compact.buttons > .ui.red.compact.button > img {
  filter: invert(1);
}

.sorted {
  padding: 2px;
  margin: 2px;
  cursor: pointer;
}

.sorted.active {
  color: #db2828;
  border-bottom: 2px solid #db2828;
}

.ui.basic.button.trailer,
.ui.basic.button.trailer:hover {
  padding: 0px;
  background: none !important;
  box-shadow: unset !important;
}

.movie-elements > .ui.label {
  padding-left: 15px !important;
  padding-right: 15px !important;
  margin: 5px !important;
}

.movie-elements > .ui.label {
  background-color: #bee4ee;
  color: #018cc5;
}

.movie-elements > .ui.label > img {
  height: 1rem !important;
  filter: invert(35%) sepia(100%) saturate(797%) hue-rotate(163deg)
    brightness(98%) contrast(105%);
}

.movie-elements > .ui.label.Nah {
  background-color: #eec8be;
  color: #c53a01;
}

.movie-elements > .ui.label.Nah > img {
  height: 1rem !important;
  filter: invert(23%) sepia(95%) saturate(1928%) hue-rotate(8deg)
    brightness(96%) contrast(105%);
}

.movie-elements > .ui.label span {
  color: black;
}

.reviews > .ui.feed > .review-divider {
  margin: 20px 0px !important;
}

.reviews > .ui.feed .label {
  padding-top: 10px;
}

.reviews > .ui.feed > .event > .label + .content {
  margin-left: 0.5rem;
}

.ui.doubling.five.cards > .ui.card > .extra.content .ui.right.label {
  font-size: 1.2rem;
}

.bio-tab .ui.menu.text {
  justify-content: center;
}

.bio-tab .ui.menu.text .item {
  border-bottom: 2px solid #dedddd;
  font-weight: 700;
  padding: 1rem;
}

@media only screen and (max-width: 767px) {
  [class*="mobile hidden"] {
    display: none !important;
  }
  .ui.cards > .ui.card > .image > .image {
    height: 200px;
  }
  .ui.card > .content .star.icon,
  .ui.cards > .card > .content .star.icon {
    margin-right: 0.3rem;
    vertical-align: initial;
    font-size: 1.2rem;
  }
  /* .ui.doubling.five.cards > .ui.card > .extra.content {
    padding: 0.75rem;
  } */

  /* .ui.doubling.five.cards > .ui.card > .extra.content .ui.right.label {
    padding: 0.5rem;
  }
  
  .ui.doubling.five.cards > .ui.card > .extra.content .ui.red.button {
    padding: 0.5rem;
  } */
}

@media only screen and (max-width: 390px) {
  .ui.menu.fixed .item {
    font-size: 1.1rem;
    padding: 0 0.5rem;
  }
}
